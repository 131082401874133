import { useState, useEffect } from 'react'
import { Box, Typography, Button, Stack, Divider, Paper } from '@newulife/common'
import { Collapse } from '@mui/material'
import { useContentfulContext } from 'hooks/useContentful'
import { find } from 'lodash'

const TIERS = {
  Bronze: {
    name: 'Bronze',
    dark: '#BA7D5F',
    light: '#F3C4B3',
    nextRank: 'Silver'
  },
  Silver: {
    name: 'Silver',
    dark: '#6E6E6E',
    light: '#C5C5C5',
    nextRank: 'Gold'
  },
  Gold: {
    name: 'Gold',
    dark: '#B29464',
    light: '#EFD3A5',
    nextRank: 'Platinum'
  },
  Platinum: {
    name: 'Platinum',
    dark: '#5367A4',
    light: '#AEC9FD'
  }
}

export default function LoyaltyProgress({
  currentRank = 'Bronze',
  earnedMonths = 0,
  missedMonths = 0,
}) {
  const data = useContentfulContext({ entryPoint: 'fields.banner8Ref.fields.repeaterList' })
  const tierColors = TIERS[currentRank] || TIERS.Bronze
  const nextRank = TIERS[TIERS[currentRank].nextRank]
  const pathLength = 439.2
  const totalMonths = 12
  const monthLength = pathLength / totalMonths

  const getValue = key => find(data, { key })?.value

  function setProgress(path, monthsCount) {
    if (!path) return
    const progressLength = monthsCount * monthLength
    const newDashArray = pathLength + progressLength
    path.style.strokeDasharray = newDashArray
  }

  useEffect(() => {
    const earnedPath = document.getElementById('earned-path')
    if (earnedPath) earnedPath.style.stroke = tierColors.dark
    setProgress(earnedPath, earnedMonths)
  }, [earnedMonths, tierColors.dark, tierColors.light])

  return (
    <Paper
      sx={{
        width: '100%',
        maxWidth: 350,
        px: 2
      }}
      elevation={0}
    >
      <Box
        sx={{
          color: '#007EC3',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          py: 1.5,
          fontWeight: 700,
          WebkitTapHighlightColor: 'transparent'
        }}
      >
        <h1 style={{textAlign:'center'}}>{getValue('loyalty_progress')}</h1>
      </Box>

        <div>
          <Divider />
          <Box
            sx={{
              color: '#6e6e6e',
              fontSize: 18,
              fontWeight: 400,
              py: 1,
              textAlign: 'center'
            }}
          >
            {getValue('current_rank')}: <span style={{ color: tierColors.dark, fontWeight: 900 }}>{getValue(tierColors.name)}</span>
          </Box>
          <Divider />

          <Stack
            sx={{
              display: 'grid',
              gap: 5,
              pt: 1.5,
              pb: 2.5
            }}
          >
            <Stack
              sx={{
                display: 'grid',
                gap: 2
              }}
            >
              <Stack
                sx={{
                  display: 'grid',
                  gap: 0.5
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyItems: 'center',
                    position: 'relative',
                    width: '100%',
                    height: 210
                  }}
                >
                  <svg
                    height="175"
                    viewBox="0 0 209 175"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{
                      width: '100%',
                      height: 'auto',
                      marginBottom: 10,
                      transform: 'scaleX(-1)'
                    }}
                  >
                    {/* Background path */}
                    <path
                      d="M178.028 167C192.355 150.162 201 128.341 201 104.5C201 51.2045 157.795 8 104.5 8C51.2045 8 8 51.2045 8 104.5C8 128.341 16.6453 150.162 30.972 167"
                      stroke="#eee"
                      strokeWidth="15"
                      strokeLinecap="round"
                    />

                    {/* Earned path */}
                    <path
                      id="earned-path"
                      className="animated-path"
                      d="M178.028 167C192.355 150.162 201 128.341 201 104.5C201 51.2045 157.795 8 104.5 8C51.2045 8 8 51.2045 8 104.5C8 128.341 16.6453 150.162 30.972 167"
                      strokeDasharray="439.2"
                      strokeDashoffset="439.2"
                      stroke="#eee"
                      strokeWidth="15"
                      strokeLinecap="round"
                      style={{
                        strokeDashoffset: pathLength,
                        transition: 'stroke-dasharray 1s ease-in-out',
                      }}
                    />
                  </svg>

                  {earnedMonths > 1 && (
                    <Box
                      sx={{
                        position: 'absolute',
                        top: 80,
                        left: 0,
                        right: 0,
                        textAlign: 'center',
                        display: 'grid',
                        gap: 1.5
                      }}
                    >
                      <Box
                        sx={{
                          color: '#aaaaaa',
                          fontSize: 55,
                          fontWeight: 500
                        }}
                      >
                        {earnedMonths}
                      </Box>
                      <Box
                        sx={{
                          color: '#aaaaaa',
                          fontSize: 16,
                          margin: 'auto',
                          maxWidth: 100
                        }}
                      >
                        {getValue('consecutive_months')}
                      </Box>
                    </Box>
                  )}
                </Box>

                {nextRank && (
                  <Stack
                    sx={{
                      display: 'grid',
                      justifyItems: 'center',
                      textAlign: 'center',
                      gap: 0.5
                    }}
                  >
                    <Box
                      sx={{
                        color: '#6e6e6e',
                        fontSize: 20,
                        fontWeight: 400
                      }}
                    >
                      {getValue('next_rank')}
                    </Box>
                    <Box
                      sx={{
                        color: nextRank.dark,
                        fontSize: 28,
                        fontWeight: 900
                      }}
                    >
                      {getValue(nextRank.name)}
                    </Box>
                  </Stack>
                )}
              </Stack>

              {missedMonths <= 1 && (
                <Box
                  sx={{
                    display: 'flex',
                    gap: 0.5,
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="20px"
                    viewBox="0 -960 960 960"
                    width="20px"
                    fill="#993b26"
                  >
                    <path d="M139.93-164q-10.08 0-17.4-4.42-7.31-4.42-11.37-11.66-4.68-7.08-4.15-16 .53-8.92 5.08-17.16l340.43-565.52q4.56-8.24 11.93-11.66t15.58-3.42q8.2 0 15.55 3.42 7.34 3.42 11.9 11.66l340.43 565.52q4.55 8.24 5.08 17.16.53 8.92-4.15 16-4.49 7.14-11.59 11.61T820.07-164H139.93ZM175-216h610L480-724 175-216Zm304.79-55.69q11.94 0 20.23-8.08 8.29-8.08 8.29-20.02t-8.08-20.23q-8.08-8.29-20.02-8.29t-20.23 8.08q-8.29 8.08-8.29 20.02t8.08 20.23q8.08 8.29 20.02 8.29Zm.01-104.62q10.97 0 18.58-7.47 7.62-7.48 7.62-18.53v-140q0-11.05-7.42-18.52-7.42-7.48-18.38-7.48-10.97 0-18.58 7.48-7.62 7.47-7.62 18.52v140q0 11.05 7.42 18.53 7.42 7.47 18.38 7.47ZM480-470Z" />
                  </svg>
                  <Typography
                    sx={{
                      color: '#993b26',
                      fontSize: 14,
                      fontWeight: 600,
                      textAlign: 'center'
                    }}
                  >
                    {missedMonths} {getValue('free_months_remaining')}
                  </Typography>
                </Box>
              )}
            </Stack>

            <Box sx={{ display: 'grid', justifyContent: 'center', gap: 2 }}>
              {/* <Button variant='contained'>{getValue('view_included_orders')}</Button> */}
              <Button
                variant='text'
                component="a"
                href={getValue('program_details_link') || '#'}
                target='_blank'
                sx={{
                  '&:hover, &:focus, &:active': {
                    color: '#0b496f !important',
                    textDecoration: 'underline',
                    background: 'transparent !important'
                  }
                }}
              >
                {getValue('view_program_details')}
              </Button>
            </Box>
          </Stack>
        </div>
    </Paper>
  )
}
