import React, { useEffect } from "react"
import { Stack, Box, Container, AppBar } from "@newulife/common"
import LanguageDropdownContentful from "components/dropdowns/LanguageDropdownContentful"
import LoyaltyProgress from './LoyaltyProgress'
import { useContentfulContext } from 'hooks/useContentful'
import { useApp } from "contexts/AppContext"

function mapLoyaltyDataToProps(customer = {}) {
  const res = {};
  res.currentRank = customer['New U Rewards Loyalty Status'];
  res.earnedMonths = parseFloat(customer['New U Rewards Month Counter'] || 0);
  res.missedMonths = parseFloat(customer['New U Rewards Missed Months'] || 0);
  return res;
}

const bp = 'lg'

const LoyaltyPage = () => {
  const {
    state: { rep, customer },
  } = useApp()

  const data = useContentfulContext({ entryPoint: 'fields.headTagModelRef.fields' })
  const imgSrc = data?.image?.fields?.file?.url
  const imgAlt = data?.image?.fields?.title

  useEffect(() => {
    if(!customer & !!rep) {
      window.location.replace('/profile/info')
    }
  },[rep, customer])
  
  return (
    <Container>
      <AppBar
      sx={{
        bgcolor: 'background.paper',
        color: 'text.main',
      }}
      position="fixed"
    >
      <Stack
        display="grid"
        gridTemplateColumns="auto 1fr"
        justifyContent={bp && {
          [bp]: 'flex-end',
        }}
        alignItems="center"
        alignContent="center"
        px={{
          xs: 2,
          sm: 3,
        }}
        py={1.5}
      >
        <Box
          maxWidth="100px"
          height="40px"
        >
          <img style={{ height: '100%' }} src={imgSrc} alt={imgAlt} />
        </Box>
        <Box
          display="grid"
          justifyContent="flex-end"
          alignItems="center"
          alignContent="center"
          px={{
            xs: 2,
            sm: 3,
          }}
          py={1.5}
        >
          <LanguageDropdownContentful />
        </Box>
        </Stack>
      </AppBar>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          pt: 10,
        }}
      >
        <LoyaltyProgress 
          {...mapLoyaltyDataToProps(customer)}
        />
      </Box>
    </Container>
  )
}

export default LoyaltyPage
