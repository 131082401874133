import { Typography } from '@newulife/common'

const AddressWrapper = ({
  children,
}) =>
  <Typography
    as="div"
    variant="body1"
    sx={{
      display: 'grid',
      color: 'neutralCool.dark',
      pl: 1,
      gap: .5,
      borderLeft: '1px solid #9a9b9c',
    }}
  >
    {children}
  </Typography>

export const AddressText = ({ addressType, form, country, shipCountry }) => {
  const {
    address1,
    address2,
    city,
    state,
    postalCode,
    shipAddress1,
    shipAddress2,
    shipCity,
    shipState,
    shipPostalCode,
  } = form

  switch (addressType) {
    case 'Billing':
      return (
        <AddressWrapper>
          {address1 && <div>{address1}</div>}
          {address2 && <div>{address2}</div>}
          {(country !== "KOREA (THE REPUBLIC OF)" && city) && <div>{city}</div>}
          {(country !== "KOREA (THE REPUBLIC OF)" && state) && <div>{state}</div>}
          {postalCode && <div>{postalCode}</div>}
        </AddressWrapper>
      )
    case 'Shipping':
      return (
        <AddressWrapper>
          {shipAddress1 && <div>{shipAddress1}</div>}
          {shipAddress2 && <div>{shipAddress2}</div>}
          {(shipCountry !== "KOREA (THE REPUBLIC OF)" && shipCity) && <div>{shipCity}</div>}
          {(shipCountry !== "KOREA (THE REPUBLIC OF)" && shipState) && <div>{shipState}</div>}
          {shipPostalCode && <div>{shipPostalCode}</div>}
        </AddressWrapper>
      )
    default:
      throw new Error(`Unhandled address type in AddressText: ${addressType}`)
  }
}

export const KoreaAddressText = ({ addressType, form }) => {
  const {
    address1,
    address2,
    postalCode,
    shipAddress1,
    shipAddress2,
    shipPostalCode,
  } = form

  switch (addressType) {
    case 'Billing':
      return (
        <AddressWrapper>
          {address1 && <div>{address1}</div>}
          {address2 && <div>{address2}</div>}
          {postalCode && <div>{postalCode}</div>}
        </AddressWrapper>
      )
    case 'Shipping':
      return (
        <AddressWrapper>
          {shipAddress1 && <div>{shipAddress1}</div>}
          {shipAddress2 && <div>{shipAddress2}</div>}
          {shipPostalCode && <div>{shipPostalCode}</div>}
        </AddressWrapper>
      )
    default:
      throw new Error(`Unhandled address type in AddressText: ${addressType}`)
  }
}

export const SOAddressText = ({ so }) => {
  const { Address1, Address2, Locality, AdministrativeArea, PostalCode } = so
  return (
    <AddressWrapper>
      {Address1 && <div>{Address1}</div>}
      {Address2 && typeof Address2 === 'string' && typeof Locality === 'string' && !Address2.includes(Locality) && <div>{Address2}</div>}
      {Locality && <div>{Locality}</div>}
      {AdministrativeArea && <div>{AdministrativeArea}</div>}
      {PostalCode && <div>{PostalCode}</div>}
    </AddressWrapper>
  )
}
