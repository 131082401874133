import { useEffect, useState, useMemo } from "react"
import { ReactComponent as DropdownSVG } from "assets/svg/dropdown.svg"
import { defaultLanguage } from "constants/language"
import { useApp } from "contexts/AppContext"
import { Stack, Box, Typography, Menu, MenuItem } from '@newulife/common'

export default function LanguageDropdown() {
  const {
    state: {
      rep: { DefaultLocaleID },
      rep,
      customer,
      languages,
      locale: stateLocale,
    },
    dispatch,
  } = useApp()
  const [currentLanguage, setCurrentLanguage] = useState(defaultLanguage)
  const [hasCheckedRep, setHasCheckedRep] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)

  const filteredLanguages = useMemo(() => {
    return languages?.filter(o => o.lang !== currentLanguage.lang)
  }, [currentLanguage, languages])

  const hasOtherLanguages = filteredLanguages?.length > 0

  useEffect(() => {
    const repDefaultLanguage = languages.find(
      (language) => language.locale === stateLocale
    )

    if (repDefaultLanguage) {
      setCurrentLanguage(repDefaultLanguage)
      dispatch({ type: "updateLocale", locale: repDefaultLanguage.locale })
    }


    setHasCheckedRep(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateLocale, languages])

  const handleChange = (e, language) => {
    e.preventDefault()
    setCurrentLanguage(language)
    dispatch({ type: "updateLocale", locale: language.locale })
    setAnchorEl(null)
  }

  const handleOpen = e => {
    setAnchorEl(e.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  if (!hasCheckedRep) return null

  return (
    <div
      data-testid="select-right-submenu"
    >
      <Stack
        display="inline-grid"
        gap={1}
        gridAutoFlow="column"
        justifyContent="flex-start"
        alignItems="center"
        alignContent="center"
        sx={{
          cursor: hasOtherLanguages ? 'pointer' : undefined,
        }}
        onClick={handleOpen}
      >
        <Typography
          variant="button"
          data-testid="langMenuValue"
          onClick={e => e.preventDefault()}
        >
          {currentLanguage.lang}
        </Typography>
        {hasOtherLanguages && (
          <Box
            display="inline-flex"
            sx={theme => ({
              transform: Boolean(anchorEl) ? 'rotate(-180deg)' : '',
              transition: theme.transitions.create(['transform']),
            })}
          >
            <DropdownSVG />
          </Box>
        )}
      </Stack>
      {hasOtherLanguages && (
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          sx={{
            marginTop: '10px',
          }}
        >
          {filteredLanguages.map(o => (
            <MenuItem
              data-testid={`submenu-selection_${o.lang}`}
              key={o.lang}
              value={o.lang}
              onClick={e => handleChange(e, o)}
            >
              {o.lang}
            </MenuItem>
          ))}
        </Menu>
      )}
    </div>
  )
}
