import { useEffect, useState, useMemo } from "react"
import { ReactComponent as DropdownSVG } from "assets/svg/dropdown.svg"
import { useApp } from "contexts/AppContext"
import { Stack, Box, Typography, Menu, MenuItem } from '@newulife/common'
import { useContentful } from "hooks/useContentful"

export default function LanguageDropdownContentful() {
  const {
    dispatch,
  } = useApp()
  const [currentLanguage, setCurrentLanguage] = useState({fields: {displayName: 'English', localeCode: 'en-US'}})
  const [anchorEl, setAnchorEl] = useState(null)

  const data = useContentful('4REky28lt3IGFn1lzmBL9f')
  const contentfulLanguages = data?.fields?.listArray

  const handleChange = (e, language) => {
    e.preventDefault()
    setAnchorEl(null)
    setCurrentLanguage(language)
    dispatch({ type: "updateLocale", locale: language?.fields?.localeCode })
  }

  const handleOpen = e => {
    setAnchorEl(e.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div
      data-testid="select-right-submenu"
    >
      <Stack
        display="inline-grid"
        gap={1}
        gridAutoFlow="column"
        justifyContent="flex-start"
        alignItems="center"
        alignContent="center"
        sx={{
          cursor: 'pointer',
        }}
        onClick={handleOpen}
      >
        <Typography
          variant="button"
          data-testid="langMenuValue"
          onClick={e => e.preventDefault()}
          tabIndex={0}
        >
          {currentLanguage?.fields?.displayName}
        </Typography>
          <Box
            display="inline-flex"
            sx={theme => ({
              transform: Boolean(anchorEl) ? 'rotate(-180deg)' : '',
              transition: theme.transitions.create(['transform']),
            })}
          >
            <DropdownSVG />
          </Box>
      </Stack>
      
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          sx={{
            marginTop: '10px',
          }}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
            autoFocus: true
          }}
        >
          {contentfulLanguages?.map((o, index) => (
            <MenuItem
              data-testid={`submenu-selection_${o?.fields?.displayName}`}
              key={o?.fields?.localeCode || index}
              value={o?.fields?.localeCode}
              onClick={e => handleChange(e, o)}
            >
              {o?.fields?.displayName}
            </MenuItem>
          ))}
        </Menu>
    </div>
  )
}
