import { createContext, useContext, useReducer } from 'react'
import { initialState } from 'constants/app'

const AppContext = createContext()

const reducer = (state, action) => {
  switch (action.type) {
    case 'updateIsLoading':
      return { ...state, isLoading: action.isLoading }
    case 'updateLocale':
      return { ...state, locale: action.locale }
    case 'updateRep':
      const newState = { ...state, rep: { ...state.rep, ...action.rep } }
      const updateLocale = shouldUpdateLocaleBasedOnRep(state?.locale, state?.languages, action.rep)

      // Determine if locale needs to be updated based on the new rep info
      if (updateLocale) {
        newState.locale = updateLocale;
      }
      
      newState.isLoading = false;
      return newState;
    case 'updateCustomer':
      return { 
        ...state, 
        customer: (action.customer || []).reduce((acc, item) => {
          const { Description, Value, DropdownValueLabel } = item
          acc[Description] = DropdownValueLabel || Value
          return acc
        }, {}),
      }
    case 'updateLanguages':
      return { ...state, languages: [...action.languages] }
    case 'updateAccountTypes':
      return { ...state, accountTypes: [...action.accountTypes] }
    case 'updateToken':
      return { ...state, token: action.token }
    default:
      throw new Error(`Unhandled action type in AppContext: ${action.type}`)
  }
}

// Used to store any variables that manage ui/ux in the application. (e.g. locale, repDID, etc...)
const AppContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {children}
    </AppContext.Provider>
  )
}

// Custom hook to consume context.
const useApp = () => {
  const context = useContext(AppContext)

  if (context === undefined) {
    throw new Error('useApp must be used within a AppContextProvider')
  }

  return context
}

function shouldUpdateLocaleBasedOnRep(currentLocale, currentLanguages, rep) {
  if (!currentLocale || !currentLanguages || !rep) {
    return false
  }

  const repDefaultLanguage = currentLanguages.find(
    (language) => language.DefaultLocaleID === rep.DefaultLocaleID
  )

  if(repDefaultLanguage?.locale === currentLocale) {
    return false
  } else {
    return repDefaultLanguage?.locale
  }
}

export { AppContextProvider, useApp }
