import { useEffect } from "react";
import { useApp } from "contexts/AppContext";
import axios from "axios";
import config from 'config';

export const apiClient = axios.create({
    baseURL: config.REACT_APP_API_URL
});

apiClient.interceptors.response.use(response => response, error => {
    if (axios.isAxiosError(error)) {
        if (error.response) {
            /** @type {ErrorResponse} */
            return error.response;
        }
    }
    throw error;
});

export const useApiClient = () => {
    const { state: { rep: { token } } } = useApp();

    useEffect(() => {
        const requestInterceptor = apiClient.interceptors.request.use(
            (config) => {
                if (token) {
                    config.headers.Authorization = `Bearer ${token}`;
                }
                return config;
            },
            (error) => {
                return Promise.reject(error);
            }
        );

        return () => {
            apiClient.interceptors.request.eject(requestInterceptor);
        };
    }, [token]);

    return apiClient;
};

export const authenticate = async ({ guid }) => {
    /** @type {AuthResponse | ErrorResponse} */
    const res = await apiClient
        .post('/api/auth/guid', { guid })
        .then(response => response.data);
    if (res.token) {
        apiClient.defaults.headers.Authorization = `Bearer ${res.token}`;
    }
    return res;
}

export const authenticateCustomer = async ({ guid }) => {
    /** @type {AuthResponse | ErrorResponse} */
    const res = await apiClient
        .post('/api/auth/customer/guid', { guid })
        .then(response => response.data);
    if (res.token) {
        apiClient.defaults.headers.Authorization = `Bearer ${res.token}`;
    }
    return res;
}

export const getProfileInfo = async () => {
    const res = await apiClient
        .get('/api/profile/info')
        .then(response => response.data);
    return res;
}

export const getMoolaSsoUrl = async () => {
    /** @type {MoolaSsoResponse | ErrorResponse} */
    const res = await apiClient
        .get('/api/profile/moola/sso')
        .then(response => response.data);
    return res;
};

export const getDashSsoUrl = async () => {
    /** @type {DashSsoResponse | ErrorResponse} */
    const res = await apiClient
        .get('/api/profile/dash/sso')
        .then(response => response.data);
    return res;
}
    

export const getCustomerAttributes = async () => {
    /** @type {CustomerAttributesResponse | ErrorResponse} */
    const res = await apiClient
        .get(`/api/profile/customers/attributes`)
        .then(response => response.data)
    return res;
}

/**
 * @typedef ErrorResponse
 * @prop {string} id      Request ID that caused the error
 * @prop {string} code    Standardized error code from the API
 * @prop {string} message English text description of the error
 * 
 * @typedef AuthResponse
 * @prop {string} token   JWT token required for subsequent requests
 * 
 * @typedef MoolaSsoResponse
 * @prop {string} url     The URL to SSO into the current user's Moola account
 * 
 * @typedef DashSsoResponse
 * @prop {string} url     The URL to SSO into the current user's Dash account
 * 
 * @typedef CustomerAttributesResponse
 * 
 */